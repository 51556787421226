import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Card, Col } from 'react-bootstrap';
import { LimbusCharacter } from '../common/components/lc-character';

interface IProps {
  index: number;
  handleRemoveClick(i: string): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  element: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  favList: any;
  sumWrath: number;
  sumLust: number;
  sumSloth: number;
  sumGluttony: number;
  sumGloom: number;
  sumPride: number;
  sumEnvy: number;
}

export const LCTeamBuilderSingleCharacter: React.FC<IProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [passive1status, setPassive1status] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [passive2status, setPassive2status] = useState(false);

  useEffect(() => {
    if (props.element.passive1v === 'Wrath') {
      const checkSum = props.sumWrath - props.element.passive1c;
      if (checkSum >= 0) {
        setPassive1status(true);
      } else {
        setPassive1status(false);
      }
    }

    if (props.element.passive1v === 'Lust') {
      const checkSum = props.sumLust - props.element.passive1c;
      if (checkSum >= 0) {
        setPassive1status(true);
      } else {
        setPassive1status(false);
      }
    }

    if (props.element.passive1v === 'Sloth') {
      const checkSum = props.sumSloth - props.element.passive1c;
      if (checkSum >= 0) {
        setPassive1status(true);
      } else {
        setPassive1status(false);
      }
    }

    if (props.element.passive1v === 'Gluttony') {
      const checkSum = props.sumGluttony - props.element.passive1c;
      if (checkSum >= 0) {
        setPassive1status(true);
      } else {
        setPassive1status(false);
      }
    }

    if (props.element.passive1v === 'Gloom') {
      const checkSum = props.sumGloom - props.element.passive1c;
      if (checkSum >= 0) {
        setPassive1status(true);
      } else {
        setPassive1status(false);
      }
    }

    if (props.element.passive1v === 'Pride') {
      const checkSum = props.sumPride - props.element.passive1c;
      if (checkSum >= 0) {
        setPassive1status(true);
      } else {
        setPassive1status(false);
      }
    }

    if (props.element.passive1v === 'Envy') {
      const checkSum = props.sumEnvy - props.element.passive1c;
      if (checkSum >= 0) {
        setPassive1status(true);
      } else {
        setPassive1status(false);
      }
    }

    if (props.element.passive2v === 'Wrath') {
      const checkSum = props.sumWrath - props.element.passive2c;
      if (checkSum >= 0) {
        setPassive2status(true);
      } else {
        setPassive2status(false);
      }
    }

    if (props.element.passive2v === 'Lust') {
      const checkSum = props.sumLust - props.element.passive2c;
      if (checkSum >= 0) {
        setPassive2status(true);
      } else {
        setPassive2status(false);
      }
    }

    if (props.element.passive2v === 'Sloth') {
      const checkSum = props.sumSloth - props.element.passive2c;
      if (checkSum >= 0) {
        setPassive2status(true);
      } else {
        setPassive2status(false);
      }
    }

    if (props.element.passive2v === 'Gluttony') {
      const checkSum = props.sumGluttony - props.element.passive2c;
      if (checkSum >= 0) {
        setPassive2status(true);
      } else {
        setPassive2status(false);
      }
    }

    if (props.element.passive2v === 'Gloom') {
      const checkSum = props.sumGloom - props.element.passive2c;
      if (checkSum >= 0) {
        setPassive2status(true);
      } else {
        setPassive2status(false);
      }
    }

    if (props.element.passive2v === 'Pride') {
      const checkSum = props.sumPride - props.element.passive2c;
      if (checkSum >= 0) {
        setPassive2status(true);
      } else {
        setPassive2status(false);
      }
    }

    if (props.element.passive2v === 'Envy') {
      const checkSum = props.sumEnvy - props.element.passive2c;
      if (checkSum >= 0) {
        setPassive2status(true);
      } else {
        setPassive2status(false);
      }
    }
  }, [props.favList]);

  return (
    <Col>
      <div className={`box single-character role-0`}>
        <div className="character-box">
          <Card
            className="avatar-card"
            onClick={() => props.handleRemoveClick(props.element.unitId)}
          >
            <LimbusCharacter
              slug={props.element.slug}
              mode="icon-no-link"
              showIcon
            />
            <FontAwesomeIcon icon={faXmark} width="32" className="remove" />
          </Card>
          <h5>{props.element.name}</h5>
          <div className="base-info">
            <div className={`pill ${props.element.skill1}`}>
              3x <strong>{props.element.skill1}</strong>
            </div>
            <div className={`pill ${props.element.skill2}`}>
              2x <strong>{props.element.skill2}</strong>
            </div>
          </div>
          <div className="base-info">
            <div className={`pill ${props.element.skill3}`}>
              1x <strong>{props.element.skill3}</strong>
            </div>
          </div>
          <div className="reqs">
            {/* <p className={`${passive1status ? 'active' : 'inactive'}`}>
              Combat passive is {passive1status ? 'active' : 'inactive'}.
            </p> */}
            {/* <p className={`${passive2status ? 'active' : 'inactive'}`}>
              Support passive is {passive2status ? 'active' : 'inactive'}.
            </p> */}
          </div>
        </div>
      </div>
    </Col>
  );
};
